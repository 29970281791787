<template>
  <div id="inspection-form">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>空调密码管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <el-form-item label="场站名称：">
              <el-input v-model="requestParam.stationName" placeholder="请输入场站名称" clearable>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button type="primary" :class="isStringInArray('btnNewPassword') ? '' : 'btnShowAuthority'" icon="el-icon-plus" @click="createInspection">新建密码</el-button>
          </div>
        </div>
        <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" class="table" :data="tableData" default-expand-all
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" :show-empty="false">
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="tableText">
                  {{ dataLoading ? '' : '暂无数据' }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" label="序号" type="index" :index="getIndex" width="60px">
          </el-table-column>
          <el-table-column align="center" prop="stationName" label="场站名称">
          </el-table-column>
          <el-table-column align="center" prop="stationNumber" label="场站编号">
          </el-table-column>
          <el-table-column align="center" prop="pwd" label="密码">
          </el-table-column>
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>

          <el-table-column align="center" prop="data" fixed="right" label="操作" width="180px">
            <template slot-scope="scope">
              <el-button :class="isStringInArray('btnEdit') ? '' : 'btnShowAuthority'" @click="editDialog(scope.row)" type="text">编辑</el-button>
              <span :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'">|</span>
              <el-button :class="isStringInArray('btnDelete') ? '' : 'btnShowAuthority'" @click="deleteDialog(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" maxlength="2" class="pagination-input" @input="handleEdit"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog @close="closeDialog('form')" :title="dialogTotalType == 0 ? '新建密码' : '修改密码'" :visible.sync="dialogVisible"
      width="800px">
      <div class="dialog-content">
        <div class="form-title"></div>
        <el-form class="details-form" label-position="right" ref="form" :rules="rules" :inline="true" :model="form" label-width="120px">
            <el-form-item label="变配电站" prop="stationId">
              <el-input placeholder="请选择变配电站" v-model="stationName" :readonly="true">
                <template slot="append" v-if="dialogTotalType !=1">
                  <span @click="selectStation" class="appendSlot">选择配电站</span>
                </template>
              </el-input>
            </el-form-item>
          <el-form-item label="密码:" prop="pwd">
            <el-input show-password v-model.trim="form.pwd" class="place" placeholder="请输入空调密码"></el-input>
          </el-form-item>
          <el-form-item label="二次确认密码:" prop="createBy">
            <el-input show-password v-model.trim="form.createBy" class="place" placeholder="请输入二次确认密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible('form')">确 定</el-button>
      </div>
    </el-dialog>

  
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="delete">确定要删除该密码？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteInspectionForm">确 定</el-button>
      </div>
    </el-dialog>
      <!-- 变配电站选择 -->
      <stationDialog :status="dialogStatus" :stationId="stationId" @selectedStation="getStationName"
      @statusChange="changeDialog"></stationDialog>
  </div>
</template>
<script>
import { inspectionFormApi } from "@/api/inspectionForm.js";
import { stationName, getAirConditioningList, editAirConditioning, deleteAirConditioning,newAirConditioningPassWord } from "@/api/eios.js"
import stationDialog from "./commponents/stationDialog.vue";
export default {
  name: "",
  components: { stationDialog },
  data() {
    return {
      dataLoading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        // stationId: "",
        stationName: "",
        
      },
      form: {
        stationId: '',
        pwd: '',
        createBy: '',
      },
      rules: {
        stationId: [
          { required: true, message: '请选择场站', trigger: 'change' }
        ],
        pwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
        ],
        createBy: [
          { required: true, message: '请输入二次确认密码', trigger: 'blur' },
        ],
      },
      total: 0,
      inputValue: "",
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogStatus: false,
      stationId: 0,
      stationName:'',
      detailsData: {},
      id: "",
      inspectionItem: [],
      selected: "",
      stationData: [],
      dialogTotalType: 0,
      authorityListBtn:[],
    };
  },
  created() {
    this.$store.commit('increment', 'Eios控制面板')
    this.$store.commit('selectChild', '空调密码管理')
    this.$store.commit("selectChildren", "");
    this.dataLoading = false
    // this.getList()
  },

  mounted() {
    this.queryStationName()
    this.queryOperation()
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
  },

  methods: {
        //按钮权限
    isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },

    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    //序号
    getIndex(index) {
      return (this.requestParam.pageNum - 1) * this.requestParam.pageSize + index + 1;
    },
    closeDialog(form) {
      this.form = this.$options.data().form
      this.$refs[form].resetFields();
      this.stationName=''
      this.stationId=0
    },
    //新增
    addDialogVisible(form) {
      this.$refs[form].validate((valid) => {
        console.log('996',this.form);
        if (valid) {
          if(this.form.pwd===this.form.createBy){
            let objEdit={
              id:this.id,
              pwd:this.form.pwd,
            }
            let objNew={
              stationId:this.form.stationId,
              pwd:this.form.pwd,
            }
            let func = this.dialogTotalType == 0 ? newAirConditioningPassWord(objNew) : editAirConditioning(objEdit)
            func.then(res => {
              if (res.code == 200) {
                this.dialogVisible = false
                this.$message({
                  message: res.message,
                  type: 'success',
                  duration: 3000,
                  customClass: "messageText",
                });
                this.queryOperation()
              } else {
                this.$message({
                  message: res.message,
                  type: 'error',
                  duration: 3000,
                  customClass: "messageText",
                });
              }
            })
          }else{
            this.$message({
            message: '二次确认密码要与密码一致',
            type: "warning",
            duration: 3000,
            customClass: "messageText",
          });
          }
        } else {
          return false
        }


      });
    },

    createInspection() {
      this.dialogVisible = true;
      this.dialogTotalType = 0
    },
    editDialog(el) {
      console.log(el);
      this.id = el.id
      this.dialogVisible = true
      this.stationName=el.stationName
      this.form.stationId=el.stationId
      // this.stationId= Number(el.stationId) 
      // this.form = Object.assign({}, el);
      this.dialogTotalType = 1
    },
    lookDialog(el) {
      this.dialogVisible3 = true
      this.form = Object.assign({}, el);
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryOperation();
    },

    queryOperation() {
      this.dataLoading = true
      getAirConditioningList(this.requestParam).then(res => {
        this.dataLoading = false
        if (res.code == 200) {
          this.tableData = res.data.list
          this.total = res.data.total
        }
      })
    },
    getStationName(value) {
      console.log("11111",value);
      if(value.id){

        this.form.stationId=value.id
        // this.taskForm.stationId = value.id;
        this.stationName = value.stationName;
      }
      this.dialogStatus = false;
    },
    changeDialog(value) {
      this.dialogStatus = value;
    },
    selectStation() {
     
      this.dialogStatus = true;

    },
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryOperation();
    },
    //尾页  
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryOperation();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryOperation();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryOperation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperation();
    },
    queryInspectionItem() {
      inspectionFormApi.queryInspectionItem().then((res) => {
        if (res.code === 200) {
          this.inspectionItem = res.data;
          console.log(this.inspectionItem);
        }
      });
    },
    queryInspectionForm() {
      inspectionFormApi.queryInspectionForm(this.requestParam).then((res) => {
        if (res.code === 200) {
          // this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    queryStationName() {
      stationName().then((res) => {
        if (res.code === 200) {
          this.stationData = res.data;
          this.total = res.total
        }
      });
    },
    //删除
    deleteInspectionForm() {
      deleteAirConditioning(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style scoped>
#inspection-form {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
  overflow: auto;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  padding: 16px 20px;
  background-color: #061b45;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.query-button {
  display: flex;
  /* justify-content: space-between; */
}

.query-button :deep() .el-button {
  height: 35px;
  padding: 0 20px;
  background-color: #176ad2;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  color: #e8f4ff;
  border: 0;
}

.form :deep() .el-form-item {
  margin: 0 16px 12px 0;
}

.form :deep().el-form-item__label {
  width: 61px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: left;
}

/* input */
.form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 180px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

/* :deep() .el-input .el-input__inner{
  color:#aed6ff !important;
} */
.form :deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

:deep() .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

/* 下拉选择框 */
.el-select>>>.el-select-dropdown {
  background: #041c4c !important;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041c4c !important;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

.el-select ::placeholder {
  color: #6180a8;
}

.el-select {
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table :deep() thead th {
  background-color: #09224b;
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 12px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 暂无数据 */
::v-deep .el-table__body-wrapper {
  background: #071734;
}

::v-deep .el-table__empty-text {
  color: aliceblue;
  line-height: 150px;
}

.response-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.operate span {
  margin: 0 3px;
}

.tooltip {
  text-align: center;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination :deep() .el-pagination {
  padding-left: 0;
}

.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}

.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}

.el-pagination>>>.el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  margin: 0 10px;
}

.pagination-input>>>.el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  padding: 0;
  text-align: center;
  color: #92b5da;
}

.pagination :deep().el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}

.pagination :deep() .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}

.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-content {
  margin: 18px 50px 0 47px;
}

.details-form :deep().el-form-item__label {
  width: 69px;
  color: #c9d4f1;
  font-size: 12px;
  /* text-align: left; */
  /* margin-right: 28px; */
}

.details-form :deep() .el-input .el-input__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.details-form .default-inspection :deep() .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form .selected-inspection:deep() .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: #041c4c;
}

.default-inspection :deep() .el-select-dropdown__item {
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid #06224e;
  box-sizing: border-box;
  font-size: 12px;
  color: #aed6ff;
  font-weight: 300;
}

.details-form :deep() .el-select-dropdown__item {

  display: flex;
  justify-content: space-between;
}

::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041c4c;
}

.delete {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}

/* tableLoading字体设置 */
::v-deep .el-icon-loading {
  font-size: 18px;

}

::v-deep .el-loading-text {
  font-size: 18px;

}

.tableText {
  font-size: 16px;
  color: #aed6ff;

}
::v-deep .el-input-group__append{
  background: #143168;
  border-color: #143168;
  color: #fff;
}
.details-form :deep() .el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0px 0px 0px 30px #061b45 inset;
  -webkit-text-fill-color: #aed6ff;
  border-radius: 1px;
}
::v-deep .el-input__inner{
  height: 40px;
}
</style>
